import type { PageComponentProps } from '../types'
import Link from 'next/link'
import { SHOP_PATH } from '../../../../utils/routes'
import { Image } from '../../../../components/Image'
import { Overview } from '../../components/brand-basic-info/overview'
import { PageComponentSwiperWrapper } from '../shared/page-component-swiper-wrapper'
import { PageComponentWrapper } from '../shared/page-component-wrapper'
import { isValid } from './verifier'

const PickedBrandPageList = (props: PageComponentProps<'picked_brand_page_list'>) => {
  if (!isValid(props)) return null

  const { name, description, resourceIds, meta } = props

  return (
    <PageComponentWrapper>
      <PageComponentSwiperWrapper
        title={name}
        description={description}
        slideClassName='relative !w-[343px] mr-4 last:mr-0'
        isMultiple={resourceIds.length > 1}
      >
        {resourceIds
          .filter(resourceId => meta.brandPageGroupById[resourceId])
          .map(resourceId => {
            const brandInfo = meta.brandPageGroupById[resourceId]
            return (
              <Link
                key={brandInfo.brandBasicInfo.data.name}
                href={SHOP_PATH.BRAND(brandInfo.pageKey)}
                className='border-grayscale-100 block select-none overflow-hidden rounded-lg border border-solid no-underline'
              >
                <Image
                  src={brandInfo.brandBasicInfo.data?.banner?.mobile}
                  alt={brandInfo.brandBasicInfo.data.name}
                  width={343}
                  height={173}
                  className='object-cover'
                />
                <Overview
                  className='p-5 pt-4'
                  materialCount={brandInfo.materialCount}
                  totalStudentsCount={brandInfo.totalStudentsCount}
                  averageRating={brandInfo.averageRating}
                  ratingCount={brandInfo.ratingCount}
                  showReviewCollectingPlaceholder
                />
              </Link>
            )
          })}
      </PageComponentSwiperWrapper>
    </PageComponentWrapper>
  )
}

export { PickedBrandPageList }
