import type { PageComponentProps } from '../types'

type NonNullableResourceIds = NonNullable<
  PageComponentProps<'picked_brand_page_list'>['resourceIds']
>

type NonNullableBrandPageGroupById = NonNullable<
  Pick<NonNullable<PageComponentProps<'picked_brand_page_list'>['meta']>, 'brandPageGroupById'>
>

type SafeGuardedPropsType = PageComponentProps<'picked_brand_page_list'> & {
  resourceIds: NonNullableResourceIds
  meta: PageComponentProps<'picked_brand_page_list'>['meta'] & {
    brandPageGroupById: NonNullableBrandPageGroupById
  }
}

const isValid = (
  props: PageComponentProps<'picked_brand_page_list'>,
): props is SafeGuardedPropsType => {
  const brandPageGroupById = props.meta?.brandPageGroupById ? props.meta.brandPageGroupById : null
  return !!(
    brandPageGroupById &&
    Array.isArray(props.resourceIds) &&
    props.resourceIds.length > 0 &&
    props.resourceIds.some(resourceId => brandPageGroupById[resourceId])
  )
}

export { isValid }
